import * as z from 'zod';
import { TFunction } from 'next-i18next';

/* This should implement https://github.com/colinhacks/zod/blob/master/src/ZodError.ts#L242 but i18n-ized */

const getErrorMap = (t: TFunction): z.ZodErrorMap => {
  return (error, ctx) => {
    if (error.message) return { message: error.message };

    let message;
    switch (error.code) {
      case z.ZodIssueCode.invalid_type:
        if (error.received === 'undefined') {
          message = t('Required');
        } else {
          message = t(
            'Expected {{expectedInputType}}, received {{receivedInputType}}',
            {
              expectedInputType: error.expected,
              receivedInputType: error.received,
            }
          );
        }
        break;
      case z.ZodIssueCode.unrecognized_keys:
        message = `Unrecognized key(s) in object: ${error.keys
          .map((k) => `'${k}'`)
          .join(', ')}`; // Developer only error message
        break;
      case z.ZodIssueCode.invalid_union:
        message = t('Invalid input');
        break;
      case z.ZodIssueCode.invalid_enum_value:
        message = t('Input must be one of these values: {{valueList}}', {
          valueList: error.options.join(', '),
        });
        break;
      case z.ZodIssueCode.invalid_arguments:
        message = `Invalid function arguments`; // Developer only error message
        break;
      case z.ZodIssueCode.invalid_return_type:
        message = `Invalid function return type`; // Developer only error message
        break;
      case z.ZodIssueCode.invalid_date:
        message = t('Invalid date');
        break;
      case z.ZodIssueCode.invalid_string:
        switch (error.validation) {
          case 'email':
            message = t('Invalid email');
            break;
          case 'url':
            message = t('Invalid URL');
            break;
          case 'uuid':
            message = t('Invalid UUID');
            break;
          case 'regex':
          default:
            message = t('Invalid');
            break;
        }
        break;
      case z.ZodIssueCode.too_small:
        switch (error.type) {
          case 'array':
            if (error.inclusive) {
              message = t('Should have at least {{itemCount}} items', {
                itemCount: error.minimum,
              });
            } else {
              message = t('Should have more than {{itemCount}} items', {
                itemCount: error.minimum,
              });
            }
            break;
          case 'string':
            if (error.inclusive) {
              message = t('Should be at least {{characterCount}} characters', {
                characterCount: error.minimum,
              });
            } else {
              message = t('Should be more than {{characterCount}} characters', {
                characterCount: error.minimum,
              });
            }
            break;
          case 'number':
            if (error.inclusive) {
              message = t('Value should be greater than {{number}}', {
                number: error.minimum,
              });
            } else {
              message = t(
                'Value should be greater than or equal to {{number}}',
                { number: error.minimum }
              );
            }
            break;
          default:
            message = t('Invalid input');
            break;
        }
        break;
      case z.ZodIssueCode.too_big:
        switch (error.type) {
          case 'array':
            if (error.inclusive) {
              message = t('Should have at most {{itemCount}} items', {
                itemCount: error.maximum,
              });
            } else {
              message = t('Should have less than {{itemCount}} items', {
                itemCount: error.maximum,
              });
            }
            break;
          case 'string':
            if (error.inclusive) {
              message = t('Should be at most {{characterCount}} characters', {
                characterCount: error.maximum,
              });
            } else {
              message = t('Should be less than {{characterCount}} characters', {
                characterCount: error.maximum,
              });
            }
            break;
          case 'number':
            if (error.inclusive) {
              message = t('Value should be less than or equal to {{number}}', {
                number: error.maximum,
              });
            } else {
              message = t('Value should be less than {{number}}', {
                number: error.maximum,
              });
            }
            break;
          default:
            message = t('Invalid input');
            break;
        }
        break;
      default:
        message = ctx.defaultError;
        throw new Error(message);
    }

    return { message };
  };
};

export { getErrorMap };

import * as z from 'zod';
import { TFunction } from 'next-i18next';

import { MAX_POST_TITLE_LENGTH } from 'utils/constants';

const attachment = z
  .object({
    altText: z.string().optional(),
    attachmentType: z.string().optional(),
    attachmentUrl: z.string().optional(),
    fileName: z.string().optional().nullable(),
    fileSizeInBytes: z.number().optional().nullable(),
    filestackId: z.string().optional(),
    s3Bucket: z.string().optional(),
    s3Key: z.string().optional(),
    url: z.string().optional(),
  })
  .optional()
  .nullable();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAttachmentSchema = () => {
  return attachment;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSchema = (tFunc?: TFunction) => {
  const t = tFunc || ((s) => s); // Needed for custom messages
  return z.object({
    title: z
      .string()
      .max(MAX_POST_TITLE_LENGTH)
      .refine((val) => val?.trim(), {
        message: t(
          'It must have at least one character other than blank space.'
        ),
      }),
    content: z.string().default(''),
    spaceId: z.string().refine(
      (val) => {
        return val === null || val !== 'no-space-selected';
      },
      {
        message: t('Please select a valid space.'),
      }
    ),
    attachmentAltText: z.string().optional(),
    attachment,
  });
};

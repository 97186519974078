import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Select, Label } from '@thinkific/toga-react';
import { useRouter } from 'next/router';

import { capitalize } from 'lodash';
import ModalBody from 'koba/components/Modal/ModalBody';
import ModalFooter from 'koba/components/Modal/ModalFooter';
import Button from 'koba/components/Button';
import Input from 'koba/components/Input';
import ExpandableTextArea from 'components/ExpandableTextArea';
import MentionsTextArea from 'components/Mentions/MentionsTextArea';
import AttachmentButtons from 'components/AttachmentButtons';
import AttachmentAltTextInput from 'components/AttachmentFileInput/AttachmentAltTextInput';
import { SECTION_QUERY } from 'schema/Section/queries';
import { SectionListQuery } from '__generated__/graphql/legacy/graphql';
import { features } from 'constants/featureFlags';
import { getSchema } from 'schema/Post';
import { getErrorMap } from 'components/Forms/utils/defaultErrorMap';
import { Post, Attachment } from 'schema/Post/types';
import { MAX_POST_TITLE_LENGTH, SCREEN_PHONE } from 'utils/constants';
import { useCommunityContext } from 'components/CommunityContext';
import { useWindowSize } from 'hooks/useWindowSize';
import { areEqualSets } from 'helpers/setHelpers';
import { pathnames } from 'utils/routerHelpers';

import styles from './PostForm.module.scss';

const cx = classNames.bind(styles);

type SpaceList = {
  value: string;
  text: string;
};

interface PostFormProps {
  closeModal: () => void;
  hasAutoFocus?: boolean;
  isLoading: boolean;
  onSubmit: (data: Post) => Promise<void>;
  initValues: {
    title: string;
    content: string;
    attachment?: Attachment | null;
    attachmentAltText: string;
    spaceId?: string;
  };
  spaces?: SpaceList[]; // eslint-disable-line react/no-unused-prop-types
  primaryColor: string;
  setUsersMentioned?: (usersMentioned: string[]) => void;
  usersMentioned?: string[];
  s3UploadPath: string;
  submitLabel: string;
  setIsFormDirty: (isDirty: boolean) => void;
}

export const PostForm: React.FC<PostFormProps> = ({
  closeModal,
  hasAutoFocus,
  isLoading,
  onSubmit,
  initValues,
  primaryColor,
  s3UploadPath,
  submitLabel,
  setIsFormDirty,
  setUsersMentioned,
  usersMentioned,
}) => {
  const { featureFlags, community } = useCommunityContext();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const router = useRouter();
  const { pathname } = router;
  const [isUploading, setIsUploading] = useState(false);
  const [attachmentType, setAttachmentType] = useState(
    initValues.attachment?.attachmentType
  );
  const isPhone = width < SCREEN_PHONE;
  const isCoursesToCommunitiesEnabled =
    featureFlags[features.COURSES_TO_COMMUNITIES];
  const spacesInputPages = [pathnames.home];
  const showSpacesInputPages = spacesInputPages.includes(pathname);

  const { data } = useQuery<SectionListQuery>(SECTION_QUERY, {
    variables: { communityId: community.id, isCoursesToCommunitiesEnabled },
  });

  const defaultSpaceOption = {
    value: 'no-space-selected',
    text: capitalize(
      t('components-forms-post-postForm-select_a_space', 'Select a space')
    ),
  };

  const communitySectionsEdges = data?.community?.communitySections?.edges;
  let spacesList: SpaceList[] =
    initValues && initValues.spaceId ? [] : [defaultSpaceOption];

  if (
    communitySectionsEdges &&
    communitySectionsEdges[0]?.node?.communitySpaces?.edges
  ) {
    const spaces = communitySectionsEdges[0]?.node?.communitySpaces?.edges?.map(
      (edge) => {
        return {
          value: edge?.node?.id || '',
          text: edge?.node?.name || '',
        };
      }
    );
    spacesList = [...spacesList, ...spaces];
  }

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    handleSubmit,
    setValue,
  } = useForm<Post>({
    resolver: zodResolver(getSchema(t), { errorMap: getErrorMap(t) }),
    defaultValues: {
      ...initValues,
      spaceId: initValues.spaceId || spacesList[0]?.value,
    },
  });

  const isEditing = initValues.title?.trim();

  useEffect(() => {
    setIsFormDirty(Object.keys(dirtyFields).length > 0);
  }, [Object.keys(dirtyFields).length > 0]);

  const handleAttachmentChange = useCallback(
    (attachment: Attachment | null) => {
      setIsUploading(false);
      setAttachmentType(attachment?.attachmentType);

      setValue('attachment', attachment, { shouldDirty: true });

      if (!attachment) {
        setValue('attachmentAltText', '', { shouldDirty: true });
      }
    },
    [getValues('attachment')]
  );

  const handleMentionChange = (value, onChange, mentions) => {
    onChange(value);

    if (setUsersMentioned) {
      const mentionedIds = mentions?.map((mention) => mention?.id);
      if (!areEqualSets(mentionedIds, usersMentioned)) {
        setUsersMentioned(mentionedIds);
      }
    }
  };
  const isDisabled =
    isLoading ||
    isUploading ||
    !(Object.keys(dirtyFields).length > 0) ||
    Object.keys(errors).length > 0;

  const spacesInput = (
    <div className={cx('select-group')}>
      <Controller
        control={control}
        name="spaceId"
        render={({ field }) => {
          return (
            <>
              <Label>
                {t(
                  'components-forms-post-postForm-post_in_space_label',
                  'Post in Space'
                )}
              </Label>
              <Select
                dataQA="space-select"
                errorText={errors.spaceId?.message}
                options={spacesList}
                selectLabel={
                  spacesList.find(
                    (space: SpaceList) => space?.value === field.value
                  )?.text ||
                  t(
                    'components-forms-post-postForm-select_a_space',
                    'Select a Space'
                  )
                }
                wrapperClass={cx('select-space')}
                onSelect={field.onChange}
              />
            </>
          );
        }}
      />
    </div>
  );

  return (
    <div className={cx('create-post')}>
      {/* @ts-ignore */}
      <ModalBody>
        {(showSpacesInputPages || isEditing) && <>{spacesInput}</>}
        <div className={cx('form-group')}>
          <Controller
            control={control}
            name="title"
            render={({ field }) => {
              return (
                <Input
                  extraProps={{
                    autoComplete: 'off',
                    maxLength: MAX_POST_TITLE_LENGTH,
                    'data-qa': 'create-post-title__textfield',
                  }}
                  formText={
                    errors.title?.message ||
                    t(`Max {{characterCount}} characters`, {
                      characterCount: MAX_POST_TITLE_LENGTH,
                    })
                  }
                  handleChange={field.onChange}
                  hasAutoFocus={hasAutoFocus}
                  hasError={!!errors.title?.message}
                  id="postTitle"
                  initValue={field.value}
                  label={t('Post title')}
                  name="postTitle"
                  placeholder={t('Post title')}
                  type="text"
                  required
                />
              );
            }}
          />
        </div>
        <div className={cx('form-group')}>
          <Controller
            control={control}
            name="content"
            render={({ field }) => {
              const mentionsComponent = (
                <MentionsTextArea
                  additionalClassName="post-form__modal"
                  id="postContent"
                  placeholder={t(
                    'Type @ to mention another member in your post'
                  )}
                  relevantMentions={[]}
                  textAreaClassName="mentions__text-area"
                  value={field.value || ''}
                  isModal
                  isPostForm
                  onChange={(_event, newValue, _newPlaintextValue, mentions) =>
                    handleMentionChange(newValue, field.onChange, mentions)
                  }
                />
              );

              return (
                <ExpandableTextArea
                  color={primaryColor}
                  handleInputChange={field.onChange}
                  id="postContent"
                  label={t('Post (optional)')}
                  mentionsComponent={mentionsComponent}
                  t={t}
                  value={field.value || ''}
                  isAlwaysExpanded
                  isModal
                />
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name="attachment"
          render={({ field }) => {
            // In create new, this is filestack file, in edit, this is attachment from graphql
            return (
              <AttachmentButtons
                attachment={field.value}
                isDisabled={!!field.value}
                s3UploadPath={s3UploadPath}
                isPostForm
                onChange={handleAttachmentChange}
                onUploading={useCallback(() => setIsUploading(true), [
                  field.value,
                ])}
              />
            );
          }}
        />

        {attachmentType === 'image' && (
          <Controller
            control={control}
            name="attachmentAltText"
            render={({ field }) => {
              return (
                <AttachmentAltTextInput
                  t={t}
                  value={field.value || ''}
                  onChange={field.onChange}
                />
              );
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {!isPhone && (
          /* @ts-ignore */
          <Button
            appearance="ghost"
            dataQA="create-post-cancen-btn__button"
            id="cancel-post"
            onClick={closeModal}
          >
            {t('common-cancel', 'Cancel')}
          </Button>
        )}
        {/* @ts-ignore */}
        <Button
          appearance="default"
          color={primaryColor}
          data-qa="create-post-btn__button"
          id="save-post"
          isDisabled={isDisabled}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          {submitLabel}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default PostForm;
